import {
    SET_SURVEY_LIST,
    SET_SURVEY_LIST_OPTION,
    SET_SURVEY_LIST_OPTIONSET,
    SET_SURVEY_LIST_QUESTION,
} from './-mutation-types';
import { useSurveyListsStore } from '~/stores/surveylists';
import SurveyListsService from '~/services/api/crm/SurveyListsService';
import type { SurveyList } from '~/models/SurveyList';
import { type SurveyListOption } from '~/models/SurveyListOption';
import { type SurveyListOptionSet } from '~/models/SurveyListOptionSet';
import { type SurveyListQuestion } from '~/models/SurveyListQuestion';

const Actions = {
    async fetchSurveyList(surveylistId: number) {
        const surveylistsStore = useSurveyListsStore();
        const surveylistsService = new SurveyListsService();
        const response = await surveylistsService.fetchSurveyList(surveylistId);

        surveylistsStore[SET_SURVEY_LIST](response);
    },
    async fetchSurveyListOption(surveylistOptionId: number) {
        const surveylistsStore = useSurveyListsStore();
        const surveylistsService = new SurveyListsService();
        const response = await surveylistsService.fetchSurveyListOption(surveylistOptionId);

        surveylistsStore[SET_SURVEY_LIST_OPTION](response);
    },
    async fetchSurveyListOptionSet(surveylistOptionSetId: number) {
        const surveylistsStore = useSurveyListsStore();
        const surveylistsService = new SurveyListsService();
        const response = await surveylistsService.fetchSurveyListOptionSet(surveylistOptionSetId);

        surveylistsStore[SET_SURVEY_LIST_OPTIONSET](response);
    },
    async fetchSurveyListQuestion(payload: { id: number; surveylistId: number }) {
        const surveylistsStore = useSurveyListsStore();
        const { id, surveylistId } = payload;

        const surveylistsService = new SurveyListsService();

        const response = await surveylistsService.fetchSurveyListQuestion(surveylistId, id);

        surveylistsStore[SET_SURVEY_LIST_QUESTION](response);
    },
    setSurveyList(surveylist: SurveyList) {
        const surveylistsStore = useSurveyListsStore();
        surveylistsStore[SET_SURVEY_LIST](surveylist);
    },
    setSurveyListOption(surveylistOption: SurveyListOption) {
        const surveylistsStore = useSurveyListsStore();
        surveylistsStore[SET_SURVEY_LIST_OPTION](surveylistOption);
    },
    setSurveyListOptionSet(surveylistOptionSet: SurveyListOptionSet) {
        const surveylistsStore = useSurveyListsStore();
        surveylistsStore[SET_SURVEY_LIST_OPTIONSET](surveylistOptionSet);
    },
    setSurveyListQuestion(surveylistQuestion: SurveyListQuestion) {
        const surveylistsStore = useSurveyListsStore();
        surveylistsStore[SET_SURVEY_LIST_QUESTION](surveylistQuestion);
    },
};

export default Actions;
