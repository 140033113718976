import {
    GET_SURVEY_LIST,
    GET_SURVEY_LIST_OPTION,
    GET_SURVEY_LIST_OPTIONSET,
    GET_SURVEY_LIST_QUESTION,
} from './-getter-types';
import { useSurveyListsStore } from '~/stores/surveylists';
import { type SurveyList } from '~/models/SurveyList';
import { type SurveyListOption } from '~/models/SurveyListOption';
import { type SurveyListOptionSet } from '~/models/SurveyListOptionSet';
import { type SurveyListQuestion } from '~/models/SurveyListQuestion';

const Getters = {
    [GET_SURVEY_LIST]: (): SurveyList | null => {
        const surveylistsStore = useSurveyListsStore();

        return surveylistsStore.surveylist;
    },
    [GET_SURVEY_LIST_OPTION]: (): SurveyListOption | null => {
        const surveylistsStore = useSurveyListsStore();

        return surveylistsStore.surveylistOption;
    },
    [GET_SURVEY_LIST_OPTIONSET]: (): SurveyListOptionSet | null => {
        const surveylistsStore = useSurveyListsStore();

        return surveylistsStore.surveylistOptionSet;
    },
    [GET_SURVEY_LIST_QUESTION]: (): SurveyListQuestion | null => {
        const surveylistsStore = useSurveyListsStore();

        return surveylistsStore.surveylistQuestion;
    },
};

export default Getters;
