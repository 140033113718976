import {
    SET_SURVEY_LIST,
    SET_SURVEY_LIST_OPTION,
    SET_SURVEY_LIST_OPTIONSET,
    SET_SURVEY_LIST_QUESTION,
} from './-mutation-types';
import { useSurveyListsStore } from '~/stores/surveylists';
import { type SurveyList } from '~/models/SurveyList';
import { type SurveyListOption } from '~/models/SurveyListOption';
import { type SurveyListOptionSet } from '~/models/SurveyListOptionSet';
import { type SurveyListQuestion } from '~/models/SurveyListQuestion';

const Mutations = {
    [SET_SURVEY_LIST](payload: SurveyList | null) {
        const surveylistsStore = useSurveyListsStore();
        surveylistsStore.surveylist = payload;
    },
    [SET_SURVEY_LIST_OPTION](payload: SurveyListOption | null) {
        const surveylistsStore = useSurveyListsStore();
        surveylistsStore.surveylistOption = payload;
    },
    [SET_SURVEY_LIST_OPTIONSET](payload: SurveyListOptionSet | null) {
        const surveylistsStore = useSurveyListsStore();
        surveylistsStore.surveylistOptionSet = payload;
    },
    [SET_SURVEY_LIST_QUESTION](payload: SurveyListQuestion | null) {
        const surveylistsStore = useSurveyListsStore();
        surveylistsStore.surveylistQuestion = payload;
    },
};

export default Mutations;
