import { type SurveyList } from '~/models/SurveyList';
import { type SurveyListOption } from '~/models/SurveyListOption';
import { type SurveyListOptionSet } from '~/models/SurveyListOptionSet';
import { type SurveyListQuestion } from '~/models/SurveyListQuestion';

export type SurveyListState = {
    surveylist: SurveyList | null;
    surveylistOption: SurveyListOption | null;
    surveylistOptionSet: SurveyListOptionSet | null;
    surveylistQuestion: SurveyListQuestion | null;
}

export const defaultStateData = (): SurveyListState => ({
    surveylist: null,
    surveylistOption: null,
    surveylistOptionSet: null,
    surveylistQuestion: null,
});

export const DefaultState = (): SurveyListState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
